<script setup lang="ts">
  import type {IBrandsWidget} from '~/utils/services/api';
  import {useGetHomeBrands} from '~/composables/queries/brands';

  const props = defineProps<{
    widget: IBrandsWidget
  }>();

  const {data} = useGetHomeBrands();
</script>

<template>
    <CpSectionHomeBrands v-if="data && data.data" :items-to-show="data.data" :title="props.widget.title" />
</template>
