<script setup lang="ts">
  import {useI18n} from 'vue-i18n';
  import {onMounted} from 'vue';
  import useCpBem from '~/composables/useCpBem';
  import CpSectionHeader from '~/components/cp/CpSection/CpSectionHeader/CpSectionHeader.vue';
  import CpButton from '~/components/cp/CpButton/CpButton.vue';
  import CpCardBrand from '~/components/cp/CpCardBrand/CpCardBrand.vue';
  import type {IBrand} from '~/utils/services/api/brands/brands';

  const props = defineProps<{
    title: string,
    itemsToShow: IBrand[],
  }>();

  const {b, e, em} = useCpBem('cp-section-home-brands');
  const {t} = useI18n();
  const topRow = props.itemsToShow.slice(0, Math.floor(props.itemsToShow.length / 2));
  const bottomRow = props.itemsToShow.slice(props.itemsToShow.length / 2);

  onMounted(() => {
    const scroll = document.getElementById('bottom-row');
    scroll?.scroll(45, 0);
  });

</script>

<template>
    <div :class="b">
        <CpSectionHeader
            :class="e('title')"
            :title="title"
            type="h2"
            show-top-border
        />
        <div :class="e('slider')">
            <CpCardBrand
                v-for="item in topRow"
                :id="item.id"
                :key="item.id"
                :image="item.image ?? ''"
                :link="item.link"
                :name="item.title"
                :show-name="false"
            />
        </div>
        <div id="bottom-row" :class="em('slider', { bottom: true})">
            <CpCardBrand
                v-for="item in bottomRow"
                :id="item.id"
                :key="item.id"
                :image="item.image ?? ''"
                :link="item.link"
                :name="item.title"
                :show-name="false"
            />
        </div>
        <div :class="e('button-container')">
            <CpNuxtLink page-name="brands" data-testid="go-to-brands">
                <CpButton
                    :class="e('button')"
                    :label="t('seeAll')"
                    kind="label"
                    color="primary"
                    hollow
                    full-width
                />
            </CpNuxtLink>
        </div>
    </div>
</template>

<style scoped lang="scss">
  .cp-section-home-brands {
    width: 100%;
    overflow: hidden;
    height: 255px;
    &__button-container {
      padding: 0 $cp-gutter;
    }
    &__slider {
      display: flex;
      padding: $cp-gutter;
      gap: $cp-gutter;
      overflow: auto;
      &--bottom {
        padding: 0 $cp-gutter $cp-gutter $cp-gutter;
      }
    }
    &__slider::-webkit-scrollbar {
      height: 0;
      display: none;
    }
  }
</style>

<i18n locale="es-US">
  seeAll: "Ver todas"
</i18n>
